import log from 'loglevel';

if (process.env.NODE_ENV === 'development') {
  log.setLevel('DEBUG');
} else if (process.env.NODE_ENV === 'test') {
  log.setLevel('TRACE');
} else {
  log.setLevel('INFO');
}

const originalFactory = log.methodFactory;
const pluginFactory = function pluginFactory(methodName, logLevel, loggerName) {
  const rawMethod = originalFactory(methodName, logLevel, loggerName);
  return (...args) => rawMethod(`[${methodName}]`, ...args);
};
log.methodFactory = pluginFactory;
log.setLevel(log.getLevel());

export default log;
