import './polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { isBlockIE } from './chat/common/blockIE';
import { BugsnagErrorBoundary } from 'common/BugsnagErrorBoundary';

if (!isBlockIE()) {
  ReactDOM.render(
    <BugsnagErrorBoundary>
      <App />
    </BugsnagErrorBoundary>,
    document.getElementById('app-root')
  );
}
