import React, { Component } from 'react';
import retry from 'async-retry';
import './App.scss';
import _window from './common/window';
import boot from './common/boot';
import logger from './common/logger';
import { bugsnagClient } from 'common/bugsnagClient';
import cleanUrlParams from './common/cleanUrlParams';

const { location } = _window;
// remove 'restart' url param to prevent session reset when page is reloaded
cleanUrlParams(['restart']);

// no need for ReactRouter yet, since we're only selecting the component once
const componentModulePromise = pathname => {
  const normalizedPath = pathname.replace(/\/$/, '');
  if (normalizedPath === '/redeem') {
    return import(
      /* webpackChunkName: "redeem" */
      './redeem/RedeemPage'
    );
  }
  if (normalizedPath === '/emergency') {
    return import(
      /* webpackChunkName: "jnto" */
      './redeem/JntoPage'
    );
  }
  if (/^\/(redeem|chat)\/[0-9a-f]+$/.test(normalizedPath)) {
    if (boot.isKioskMode) {
      return import(
        /* webpackChunkName: "kiosk" */
        './chat/Kiosk'
      );
    }
    return import(
      /* webpackChunkName: "chat" */
      './chat/Webchat'
    );
  }
  if (/^\/widget-config\//.test(normalizedPath)) {
    return import(
      /* webpackChunkName: "widget-config" */
      './widget-config'
    );
  }
  if (/^\/ops/.test(normalizedPath)) {
    return import(
      /* webpackChunkName: "ops" */
      './ops'
    );
  }
  return Promise.reject(new Error(`Cannot find matching component for path: ${pathname}`));
};

const componentPromise = retry(
  componentModulePromise.bind(null, location.pathname),
  { minTimeout: 500, retries: 3 } // intervals: 0.5s -> 1s -> 2s (in total 3.5s to abandon retrying)
).then(module => {
  if (!module || !module.default) {
    throw new Error(
      'No default export in dynamically imported module - check for top level runtime errors'
    );
  }
  return module.default;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { component: null };
    componentPromise
      .then(component => {
        this.setState({ component });
      })
      .catch(error => {
        logger.error(error);
        bugsnagClient.notify(error);
      });
  }

  componentDidMount() {
    componentPromise.then(() => {
      const splash = document.querySelector('.splash');
      if (splash) {
        splash.remove();
      }
    });
  }

  render() {
    if (!this.state.component) return null;
    return <this.state.component />;
  }
}

export default App;
