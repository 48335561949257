import _window from './window';

const { history, location, URLSearchParams } = _window;

const cleanUrlParams = (blacklist = []) => {
  // URLSearchParams not avail in legacy browsers
  if (!URLSearchParams) return;
  const search = location.search;
  if (!search) return;
  // remove the initial ?
  const paramsString = search.slice(1);
  const params = new URLSearchParams(paramsString);

  let isCleaningRequired = false;

  blacklist.forEach(item => {
    if (params.get(item) === null) return;
    isCleaningRequired = true;
    params.delete(item);
  });

  if (!isCleaningRequired) return;
  let updatedSearch = params.toString();
  if (updatedSearch.length > 0) updatedSearch = `?${updatedSearch}`;

  history.replaceState(history.state, '', `${location.pathname}${updatedSearch}`);
};

export default cleanUrlParams;
