export function GetIEVersion() {
  const sAgent = window.navigator.userAgent;
  const Idx = sAgent.indexOf('MSIE');
  // If IE, return version number.
  if (Idx > 0) {
    return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf('.', Idx)), 10);
  }
  // If IE 11 then look for "Trident" in user agent string.
  else if (navigator.userAgent.match(/trident/gi)) {
    return 11;
  }
  // If IE Edge then look for "Edge" in user agent string.
  else if (navigator.userAgent.match(/edge/gi)) {
    return 13;
  }
  return 0; // It is not IE
}

export function isBlockIE() {
  const ieVersion = GetIEVersion();
  // Block IE only on redeem/chat/widget page for IE 13 and up. Block all pages for older versions
  if (
    (ieVersion > 0 && ieVersion < 13) ||
    (ieVersion >= 13 && document.getElementById('app-root'))
  ) {
    document.documentElement.className += ' ie';
    if (document.getElementsByClassName('unsupported-browser-ie')[0]) {
      document.getElementsByClassName('unsupported-browser-ie')[0].style.display = 'block';
    }
    return true;
  }
  return false;
}
