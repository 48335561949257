import Bugsnag from '@bugsnag/js';
import BugsnagReact from '@bugsnag/plugin-react';
import boot from 'common/boot';

const reportExtensions = [];

export const isBugsnagEnabled = () => boot.bugsnag && boot.bugsnag.apiKey;

if (isBugsnagEnabled()) {
  Bugsnag.start({
    ...boot.bugsnag,
    plugins: [new BugsnagReact()],
    onError: event => {
      reportExtensions.forEach(extension => extension(event));
    },
  });
}

export const bugsnagClient = isBugsnagEnabled() ? Bugsnag : { notify: () => {} };

export const extendReport = fn => {
  reportExtensions.push(fn);
};
